import React from 'react'
import * as styles from './../styles/TemplateArticle.module.scss'

import LayoutRoot from '../components/LayoutRoot'
import Breadcrumbs from '../components/Breadcrumbs'
import AuthMarkdown from '../components/AuthMarkdown'

interface PediaProps {
  pageContext: {
    pedia: Pedia
  }
}

const Pedia: React.FC<PediaProps> = ({ pageContext: { pedia } }) => {
  const breadcrumbs = [
    {
      name: '移民百科',
      path: '/pedia'
    },
    {
      name: '百科详情'
    }
  ]
  return (
    <LayoutRoot noNavigator>
      <div className={styles.articleWrapper}>
        <div className={styles.breadWrapper}>
          <Breadcrumbs data={breadcrumbs} />
        </div>
        <div className={styles.articleTitle}>{pedia?.title}</div>
        <div className={styles.divider} />
        <div className={styles.contentWrapper}>
          <AuthMarkdown content={pedia?.content.data?.content} />
        </div>
      </div>
    </LayoutRoot>
  )
}

export default Pedia
